<template>
  <div class="main" :style="mainHeight">
    <div class="tab_header">
        <ul class="div">
          <li class="top_li">
            <img :src="balanceAccount" class="balanceAccount">
            <div class="center">
              <p class="large_font">{{user.userInfor.balance}}</p>
              <p>账户余额（元）</p>
            </div>
            <router-link to="/pay" class="to_pay" tag="a" v-if="paramInfo.wechatPayFlag === 'Y'">充值</router-link>
          </li>
          <!-- <li class="center_li" style="margin-top:-4.5rem;">
            <span class="line">
              跳闸余额设置为：<font>-{{paramInfo.maxArrearage}}元</font>
            </span>
            <span class="line small_span">
              当账户余额为-{{paramInfo.maxArrearage}}元时，平台将自动跳闸
            </span>
          </li> -->
          <li class="center_li">
            <span class="line">
              余额预警天数设置为：<font>{{paramInfo.balanceAlarmDays}}天</font>
            </span>
            <span class="line small_span">
              当账户余额预计可用天数达到{{paramInfo.balanceAlarmDays}}天时，平台将进行消息提醒
            </span>
          </li>
          <li class="center_li">
            <span class="line" style="margin-bottom:0.8rem;">
              账单汇总（近6个月）
            </span>
            <div class="charts" v-show="showCanvas">
              <div id="my_charts" ref="my_charts" :style="myChartsSize"></div>
            </div>
          </li>

          <li class="center_li" @click="$router.push('/expensesPay')">
            <span class="left_span">
              充值明细
            </span>
            <img :src="leftIco" class="right_img">
          </li>

          <li class="center_li" style="margin-bottom:1.5rem" @click="$router.push('/expenses')">
            <span class="left_span">
              扣费明细
            </span>
            <img :src="leftIco" class="right_img">
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
  import { userDetail } from '@/api/home'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { userDB } from '@/db'
  import balanceAccount from '@/assets/home/balance_ico1.png'
  import leftIco from '@/assets/home/left_ico.png'
  import echarts from 'echarts'
  import api from '@/api/index'
  const { getRechargesInfor, fetchData } = api
  export default {
    data() {
      return {
        showCanvas: false,
        userName: '',
        showSelet: false,
        mainHeight: `height:${window.innerHeight || document.documentElement.clientHeight}px;overflow:hidden`,
        userRemainder: '',
        companyName: '',
        pageCount: 0,
        loading: false,
        finished: true,
        myChartsSize: 'width:300px;height:18rem',
        lists: [],
        showActive: -1,
        currentList: [],
        balanceAccount,
        paramInfo: {},
        leftIco,
        countAmount: 0,
        rechargeFee: { 'fee': { 'amount': [], 'dateDate': [] }, 'recharge': { 'amount': [], 'dateDate': [] }},
        option: {
          legend: {
            data: ['充值金额', '扣费金额']
          },
          toolbox: {
          },
          xAxis: [
            {
              type: 'category',
              data: [1],
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              // interval: 50,
              name: '金额（元）',
              axisLabel: {
                margin: 2,
                formatter: function(value, index) {
                  if (value >= 10000 && value < 10000000) {
                    value = value / 10000 + '万'
                  } else if (value >= 10000000) {
                    value = value / 10000000 + '千万'
                  }
                  return value
                }
              }
            }
          ],
          grid: {
            left: 35
          },
          series: [
            {
              name: '充值金额', //  充值金额
              type: 'bar',
              smooth: true,
              itemStyle: { normal: { color: 'rgb(202, 226, 249)' }},
              data: [111]
              // data: this.user.rechargeFeeList.fee.amount
            },
            {
              name: '扣费金额', // 扣费金额
              type: 'bar',
              smooth: true,
              itemStyle: { normal: { color: 'rgb(102, 166, 255)' }},
              data: [121]
              // data: this.user.rechargeFeeList.recharge.amount
            }
          ]
        }
      }
    },
    computed: {
      ...mapState(['user', 'global'])
    },
    filters: {
      clearTime(val) {
        if ((!!val) && (val.indexOf('T'))) {
          return val.split('T')[0]
        }
        return val
      }
    },
    created() {
      this.myChartsSize = `width:${document.body.offsetWidth * 0.9}px;height:18rem`
      this.getPramaInfo()
    },
    mounted() {
      const _self = this
      _self.showCanvas = true

      const rechargeFeeList = this.user.rechargeFeeList
      for (var key in rechargeFeeList) {
        for (let i = 0; i < rechargeFeeList[key].dateDate.length; i++) {
          if (this.checkDate(rechargeFeeList[key].dateDate[i])) {
            this.rechargeFee[key].amount.push(rechargeFeeList[key].amount[i])
            this.rechargeFee[key].dateDate.push(rechargeFeeList[key].dateDate[i])
          }
        }
      }

      this.option.series[0].data = this.rechargeFee.recharge.amount
      this.option.series[1].data = this.rechargeFee.fee.amount
      this.option.xAxis[0].data = this.formatMonth(this.rechargeFee.fee.dateDate)
      const myChart = echarts.init(document.getElementById('my_charts'))
      myChart.setOption(this.option)
    },
    methods: {
      checkDate(obj) {
        if ((this.global.currentYear === parseInt(obj.split('-')[0])) && (this.global.currentMonth <= parseInt(obj.split('-')[1]) + 6)) {
          return true
        } else if ((this.global.currentYear === obj.split('-')[0] - 1) && (12 - parseInt(obj.split('-')[1]) + this.global.currentMonth >= 6)) {
          return true
        } else {
          return false
        }
      },
      getPramaInfo() {
        fetchData(
          {},
          `/config/paramInfo`,
          'get'
        ).then(res => {
          this.paramInfo = res
        }).catch(err => {
          console.log(err)
        })
      },
      formatDays(val) {
        if (parseInt(val) < 10) {
          return `0${val}`
        } else {
          return val
        }
      },
      formatMonth(obj) {
        const arr = []
        for (let i = 0; i < obj.length; i++) {
          arr.push(`${obj[i].split('-')[0]}-${obj[i].split('-')[1]}`)
        }
        return arr
      },
      /*
        加法函数
        @parmas arg1(被加数字) arg2(被加数字)
        @return 返回计算结果
      */
      accAdd(arg1, arg2) {
        var r1, r2, m
        try {
          r1 = arg1.toString().split('.')[1].length
        } catch (e) {
          r1 = 0
        } try {
          r2 = arg2.toString().split('.')[1].length
        } catch (e) {
          r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        return (arg1 * m + arg2 * m) / m
      }
    }
  }
</script>

<style scoped lang="scss">
  .main {
    background: #fff;
  }
  .btnBox {
    height: auto;
    overflow: hidden;
    float: left;
    margin: 20px auto;
    height: 3rem;
    width: 100%;
    a {
      display: inline-block;
      width: 33%;
      margin-left: 10%;
      text-align: center;
      background: #ea4a45;
      color: #fff;
      border: 1px solid #ea4a45;
      border-radius: 40px;
    }
  }
  .tab_header {
    width:100%;
    float: left;
    font-size: 1rem;
    height: 100%;
    overflow: auto;
    background: #fff;
    ul {
      height: auto;
      min-height: 3rem;
      line-height: 2.4rem;
      color: #333;
      background: #E6E6E6;
      width: 100%;
      float: left;
      padding:0;
      font-size: 1.1rem;
      li {
        background: #fff;
        height: auto;
        min-height: 2rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        overflow: hidden;
        .charts {
          width: 90%;
          height: 16.5rem;
          overflow: hidden;
          float: left;
          margin-top: 1rem;
          margin: 0 5%;
        }
        #my_charts {
          margin: 0 auto;
          margin-bottom: 2rem;
        }
        .to_pay {
          display: inline-block;
          width: 5rem;
          height: 2rem;
          border-radius:0.3rem;
          line-height: 2rem;
          margin-top: 1rem;
          margin-right: 1rem;
          border: 1px solid #FFBE3E;
          background: #FFBE3E;
          text-align: center;
          color: #fff;
        }
        .center {
          float: left;
          width: 40%;
          height: 4rem;
          p {
            line-height: 1.6rem;
            padding: 0;
            margin: 0;
            font-size: 0.8rem;
          }
          p:first-child {
            margin-top: 0.4rem;
            font-size: 1.4rem;
          }
        }
        .line {
          display: inline-block;
          width: calc(100% - 1.6rem);
          height: 1.5rem;
          line-height: 1.5rem;
          padding-left:0.8rem;
          font-size: 1.1rem;
          font {
            font-weight: 600;
          }
        }
        .small_span {
          font-size: 0.8rem;
          height: auto;
          line-height: 1.4rem;
          margin-top: 0.6rem;
          color:#666;
        }
        .balanceAccount {
          float: left;
          height: 3rem;
          margin: 0.5rem 0.8rem;
        }
      }
      li:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }
      .top_li {
        background: #66A6FF;
        color: #fff;
        padding: 1.5rem 0;
        min-height: 7.6rem;
      }
      .center_li {
        width:90%;
        margin: 1rem 5%;
        border-radius: 20px;
        padding: 1rem 0;
        box-shadow: 0px 0px 20px #cdcdcd;
        .left_span {
          padding-left:0.8rem ;
        }
        .right_img {
          height: 1rem;
          float: right;
          margin-top: 0.5rem;
          margin-right: 0.8rem;
        }
      }
    }
  }
</style>
